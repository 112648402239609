<script>
import i18n from '@/i18n'
// import service from '@/server/http_service'
import geo_data from '@/assets/file/geo_data.JSON'
// import simplebar from 'simplebar-vue'
// import 'simplebar/dist/simplebar.min.css'
/**
 * Starter page component
 */
export default {
  page: {
    // title: 'หน้าว่าง',
    meta: [{ name: 'description' }],
  },
  components: { },
  props: {
    obj: {
      type: Object,
      default: () => {
        return { message: 'no obj' }
      },
    },
  },
  data() {
    let lang = localStorage.getItem('account_lang')
    let user = localStorage.getItem('users')
    var lang_obj = i18n.t('route_info')
    return {
      //genaral
      account_lang: lang,
      account_data: JSON.parse(user),
      lang: lang_obj,
      province_service_data: this.obj,
      transporter_lang: true,
      // route
      data_prov_data: this.obj,
      data_prov_sel: {
        1:[],
        2:[],
        3:[],
        4:[],
        5:[],
        6:[],
      },
      prov_sel_all: '',
      geo_data: geo_data,
    }
  },
  watch: {},
  beforeMount() {},
  mounted() {
    this.startModel()
  },
  methods: {
    startModel() {
      var that = this
      this.splitProvServiceData()
      setTimeout(() => {
        that.getDataAddInfo()
      }, 300)
    },
    getDataAddInfo() {
      // console.log(this.data_prov_data)
      if(!(Object.keys(this.data_prov_data).length)) {
        this.setService_route(geo_data.province, geo_data.geographies)
      }
    },
    setService_route(data, data2) {
      var prov = data
      // eslint-disable-next-line no-unused-vars
      var geo = data2
      var data_prov = {
        1: [
          {
            id: '101',
            name_th: 'ทั้งหมด',
            name_en: 'all',
            geography_id: '1',
            status: '',
          },
        ],
        2: [
          {
            id: '102',
            name_th: 'ทั้งหมด',
            name_en: 'all',
            geography_id: '2',
            status: '',
          },
        ],
        3: [
          {
            id: '103',
            name_th: 'ทั้งหมด',
            name_en: 'all',
            geography_id: '3',
            status: '',
          },
        ],
        4: [
          {
            id: '104',
            name_th: 'ทั้งหมด',
            name_en: 'all',
            geography_id: '4',
            status: '',
          },
        ],
        5: [
          {
            id: '105',
            name_th: 'ทั้งหมด',
            name_en: 'all',
            geography_id: '5',
            status: '',
          },
        ],
        6: [
          {
            id: '106',
            name_th: 'ทั้งหมด',
            name_en: 'all',
            geography_id: '6',
            status: '',
          },
        ],
      }
      // console.log(prov)
      for (let index = 0; index < prov.length; index++) {
        let rowdata = {
          id: prov[index].id,
          name_th: prov[index].name_th,
          name_en: prov[index].name_en,
          geography_id: prov[index].geography_id,
          status: this.checkProvStatus(prov[index].id),
        }
        const rowProv = rowdata
        if (rowProv.geography_id == '1') {
          data_prov['1'].push(rowProv)
        } else if (rowProv.geography_id == '2') {
          data_prov['2'].push(rowProv)
        } else if (rowProv.geography_id == '3') {
          data_prov['3'].push(rowProv)
        } else if (rowProv.geography_id == '4') {
          data_prov['4'].push(rowProv)
        } else if (rowProv.geography_id == '5') {
          data_prov['5'].push(rowProv)
        } else if (rowProv.geography_id == '6') {
          data_prov['6'].push(rowProv)
        }
      }
      // console.log(data_prov)
      this.data_prov_data = data_prov
      if (this.account_lang == 'th') {
        this.transporter_lang = true
      } else {
        this.transporter_lang = false
      }
      // this.splitProvServiceData()
    },
    splitProvServiceData() {
      var data = this.province_service_data
      // console.log(data)
      var prov_sel_all = ''
      for (let index = 0; index < data.length; index++) {
        const element = data[index]
        var row_prov = element.id_provinces
        prov_sel_all += row_prov
        // var split_prov = row_prov.split(',')
      }
      this.prov_sel_all = prov_sel_all
      // console.log(prov_sel_all)
      // ex data
      // created: "2021-07-19T06:47:35.000Z"
      // del_flg: "0"
      // id_geographies: 2
      // id_member: "105"
      // id_provinces: "102,1,49,9,17,58,47,3,4,5,53,52,54,7,2,60,59,10,8,51,57,6,48"
      // id_service_route: 141
      // lastmod: "2021-07-19T06:47:35.000Z"
      // name: "ภาคกลาง"
    },
    checkProvStatus(id) {
      var prov_id = id
      var arr_prov = this.prov_sel_all.split(',')
      var flg = false
      var data_return = ''
      for (let index = 0; index < arr_prov.length; index++) {
        const prov_row_id = arr_prov[index]
        if(prov_row_id == prov_id) {
          flg = true
        }
      }
      if(flg) {
        data_return = 'checked'
      }
      return data_return
    },
    ck_all_sel(id,optionId) {
      // console.log(optionId)
      // console.log(this.data_prov_data)
      if (this.data_prov_data[id][0].status == 'checked') {
        for (let index = 0; index < this.data_prov_data[id].length; index++) {
          if(this.data_prov_data[id][index].id != optionId) {
            this.data_prov_data[id][index].status = 'checked'
          }
        }
      } else {
        for (let index = 0; index < this.data_prov_data[id].length; index++) {
          if(this.data_prov_data[id][index].id != optionId) {
            this.data_prov_data[id][index].status = ''
          }
        }
      }
      this.$emit('select_prov', this.data_prov_data)
    },
    ck_sel(id) {
      // console.log(id)
      // console.log(this.data_prov_data)
      var flag = 0
      for (let index = 0; index < this.data_prov_data[id].length; index++) {
        const element = this.data_prov_data[id][index]
        if(element.status != 'checked' && element.id != ('10' + id)) {
          flag++
        }
      }
      if(flag != 0) {
        this.data_prov_data[id][0].status = ''
      } else {
        this.data_prov_data[id][0].status = 'checked'
      }
      this.$emit('select_prov', this.data_prov_data)
    },
  },
}
</script>

<template>
  <div>
    <h4>
      {{ $t('register_transporter.service_route.topic') }}
    </h4>
    <br />
    <b-row>
      <b-col lg="2">
        <b-row class="d-flex justify-content-center">
          <img src="@/assets/images/thailand pic/Group 2412@2x.png" height="140px" class="text-center" />
        </b-row>
        <b-row class="d-flex justify-content-center text-service-route">
          <br />
          <p class="font-color-blue">ภาคกลาง</p>
        </b-row>
      </b-col>
      <b-col lg="10">
        <b-row>
          <b-col v-for="(option, index) in data_prov_data[2]" :key="option.id" md="3">
            <template v-if="data_prov_data[2][index].id == '102'">
              <b-form-checkbox v-model="data_prov_data[2][index]['status']" value="checked" name="checkbox" class="check-col" @change="ck_all_sel(2,option.id)">
                <template v-if="transporter_lang">{{ option.name_th }}</template>
                <template v-else>{{ option.name_en }}</template>
              </b-form-checkbox>
            </template>
            <template v-else>
              <b-form-checkbox v-model="data_prov_data[2][index]['status']" value="checked" name="checkbox" class="check-col" @change="ck_sel(2)">
                <template v-if="transporter_lang">{{ option.name_th }}</template>
                <template v-else>{{ option.name_en }}</template>
              </b-form-checkbox>
            </template>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <br />
    <hr />
    <br />
    <b-row>
      <b-col lg="2">
        <b-row class="d-flex justify-content-center">
          <img src="@/assets/images/thailand pic/Group 2417@2x.png" height="140px" class="text-center" />
        </b-row>
        <b-row class="d-flex justify-content-center text-service-route">
          <br />
          <p class="font-color-blue">ภาคเหนือ</p>
        </b-row>
      </b-col>
      <b-col lg="10">
        <b-row>
          <b-col v-for="(option, index) in data_prov_data[1]" :key="option.id" md="3">
            <template v-if="data_prov_data[1][index].id == '101'">
              <b-form-checkbox v-model="data_prov_data[1][index]['status']" value="checked" name="checkbox" class="check-col" @change="ck_all_sel(1,option.id)">
                <template v-if="transporter_lang">{{ option.name_th }}</template>
                <template v-else>{{ option.name_en }}</template>
              </b-form-checkbox>
            </template>
            <template v-else>
              <b-form-checkbox v-model="data_prov_data[1][index]['status']" value="checked" name="checkbox" class="check-col" @change="ck_sel(1)">
                <template v-if="transporter_lang">{{ option.name_th }}</template>
                <template v-else>{{ option.name_en }}</template>
              </b-form-checkbox>
            </template>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <br />
    <hr />
    <br />
    <b-row>
      <b-col lg="2">
        <b-row class="d-flex justify-content-center">
          <img src="@/assets/images/thailand pic/Group 2414@2x.png" height="140px" class="text-center" />
        </b-row>
        <b-row class="d-flex justify-content-center text-service-route">
          <br />
          <p class="font-color-blue">ภาคใต้</p>
        </b-row>
      </b-col>
      <b-col lg="10">
        <b-row>
          <b-col v-for="(option, index) in data_prov_data[6]" :key="option.id" md="3">
            <template v-if="data_prov_data[6][index].id == '106'">
              <b-form-checkbox v-model="data_prov_data[6][index]['status']" value="checked" name="checkbox" class="check-col" @change="ck_all_sel(6,option.id)">
                <template v-if="transporter_lang">{{ option.name_th }}</template>
                <template v-else>{{ option.name_en }}</template>
              </b-form-checkbox>
            </template>
            <template v-else>
              <b-form-checkbox v-model="data_prov_data[6][index]['status']" value="checked" name="checkbox" class="check-col" @change="ck_sel(6)">
                <template v-if="transporter_lang">{{ option.name_th }}</template>
                <template v-else>{{ option.name_en }}</template>
              </b-form-checkbox>
            </template>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <br />
    <hr />
    <br />
    <b-row>
      <b-col lg="2">
        <b-row class="d-flex justify-content-center">
          <img src="@/assets/images/thailand pic/Group 2419@2x.png" height="140px" class="text-center" />
        </b-row>
        <b-row class="d-flex justify-content-center text-service-route">
          <br />
          <span class="font-color-blue">ภาคอีสาน</span>
          <span class="font-color-blue" style="font-size:16px">(ตะวันออกเฉียงเหนือ)</span>
        </b-row>
      </b-col>
      <b-col lg="10">
        <b-row>
          <b-col v-for="(option, index) in data_prov_data[3]" :key="option.id" md="3">
            <template v-if="data_prov_data[3][index].id == '103'">
              <b-form-checkbox v-model="data_prov_data[3][index]['status']" value="checked" name="checkbox" class="check-col" @change="ck_all_sel(3, option.id)">
                <template v-if="transporter_lang">{{ option.name_th }}</template>
                <template v-else>{{ option.name_en }}</template>
              </b-form-checkbox>
            </template>
            <template v-else>
              <b-form-checkbox v-model="data_prov_data[3][index]['status']" value="checked" name="checkbox" class="check-col" @change="ck_sel(3)">
                <template v-if="transporter_lang">{{ option.name_th }}</template>
                <template v-else>{{ option.name_en }}</template>
              </b-form-checkbox>
            </template>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <br />
    <hr />
    <br />
    <b-row>
      <b-col lg="2">
        <b-row class="d-flex justify-content-center">
          <img src="@/assets/images/thailand pic/Group 2422@2x.png" height="140px" class="text-center" />
        </b-row>
        <b-row class="d-flex justify-content-center text-service-route">
          <br />
          <p class="font-color-blue">ภาคตะวันออก</p>
        </b-row>
      </b-col>
      <b-col lg="10">
        <b-row>
          <b-col v-for="(option, index) in data_prov_data[5]" :key="option.id" md="3">
            <template v-if="data_prov_data[5][index].id == '105'">
              <b-form-checkbox v-model="data_prov_data[5][index]['status']" value="checked" name="checkbox" class="check-col" @change="ck_all_sel(5,option.id)">
                <template v-if="transporter_lang">{{ option.name_th }}</template>
                <template v-else>{{ option.name_en }}</template>
              </b-form-checkbox>
            </template>
            <template v-else>
              <b-form-checkbox v-model="data_prov_data[5][index]['status']" value="checked" name="checkbox" class="check-col" @change="ck_sel(5)">
                <template v-if="transporter_lang">{{ option.name_th }}</template>
                <template v-else>{{ option.name_en }}</template>
              </b-form-checkbox>
            </template>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <br />
    <hr />
    <br />
    <b-row>
      <b-col lg="2">
        <b-row class="d-flex justify-content-center">
          <img src="@/assets/images/thailand pic/Group 2413@2x.png" height="140px" class="text-center" />
        </b-row>
        <b-row class="d-flex justify-content-center text-service-route">
          <br />
          <p class="font-color-blue">ภาคตะวันตก</p>
        </b-row>
      </b-col>
      <b-col lg="10">
        <b-row>
          <b-col v-for="(option, index) in data_prov_data[4]" :key="option.id" md="3">
            <template v-if="data_prov_data[4][index].id == '104'">
              <b-form-checkbox v-model="data_prov_data[4][index]['status']" value="checked" name="checkbox" class="check-col" @change="ck_all_sel(4,option.id)">
                <template v-if="transporter_lang">{{ option.name_th }}</template>
                <template v-else>{{ option.name_en }}</template>
              </b-form-checkbox>
            </template>
            <template v-else>
              <b-form-checkbox v-model="data_prov_data[4][index]['status']" value="checked" name="checkbox" class="check-col" @change="ck_sel(4)">
                <template v-if="transporter_lang">{{ option.name_th }}</template>
                <template v-else>{{ option.name_en }}</template>
              </b-form-checkbox>
            </template>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <br />
    <hr />
    <br />
  </div>
</template>
<style scoped>
.modal-detail-body-custom {
  padding: 1rem 1rem;
}
.modal-scrollbar {
  overflow: hidden scroll;
}

.modal-scrollbar {
  overflow: auto;
}
</style>
