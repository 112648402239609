<script>
import i18n from '@/i18n'
import service from '@/server/http_service'
// import geo_data from '@/assets/file/geo_data.JSON'
// import simplebar from 'simplebar-vue'
// import 'simplebar/dist/simplebar.min.css'
/**
 * Starter page component
 */
export default {
  page: {
    // title: 'หน้าว่าง',
    meta: [{ name: 'description' }],
  },
  components: { },
  props: {
    obj: {
      type: Array,
      default: () => {
        return { message: 'no obj' }
      },
    },
  },
  data() {
    let lang = localStorage.getItem('account_lang')
    let user = localStorage.getItem('users')
    var lang_obj = i18n.t('select_truckType')
    return {
      //genaral
      account_lang: lang,
      account_data: JSON.parse(user),
      lang: lang_obj,
      lang_select: true,
      goods_lang: true,
      page_selcet: this.obj[2],
      // truck
      dataTruckType: this.obj[0],
      dataTruckType_ck: [],
      dataTruckType_note: [],
      dataTruckTypeGroup: this.obj[1],
      dataTruckTypeGroup_ck: [],
      dataTruckTypeGroup_note: '',
      dataNewTruck: [],
      dataGroupTruck: [
        {
          id: '1',
          name_group: 'ทั้งหมด',
        },
      ],
      open_truck_type_status: false,
      data_truck_type_sel: [],
      data_truck_type_sel_txt: null,
      data_truck_type_sel_id: null,
      data_truck_type_sel_flag: 0,
      data_truck_type_group_sel: null,
      data_truck_type_group_sel_id: null,
      data_truck_type_group_sel_flag: 0,
    }
  },
  watch: {},
  beforeMount() {},
  mounted() {
    this.checkTruckTypeData()
  },
  methods: {
    checkTruckTypeData() {
      if(this.dataTruckType.length == 0 || this.dataTruckTypeGroup.length == 0) {
        this.dataTruckType = []
        this.dataTruckTypeGroup = []
        this.getTruckType()
        this.getTruckTypeGroup()
      }
    },
    getTruckType() {
      // var that = this
      service.postService('getTruckType').then(rp => {
        // console.log(rp)
        var data = JSON.parse(JSON.stringify(rp.data))
        // console.log(data)
        for (let index = 0; index < data.length; index++) {
          let row = data[index]
          let rowdata = {
            id_group: row.id_group,
            id_model: row.id_model,
            name_group_en: row.name_group_en,
            name_group_th: row.name_group_th,
            name_model_en: row.name_model_en,
            name_model_th: row.name_model_th,
            other_flag: row.other_flag,
            prices_pre_km: row.prices_pre_km,
            prices_start: row.prices_start,
            status: '',
          }
          this.dataTruckType.push(JSON.parse(JSON.stringify(rowdata)))
          // that.dataTruckType_ck[index] = ''
        }
        // console.log(that.dataTruckType)
      })
    },
    getTruckTypeGroup() {
      // var that = this
      service.postService('getTruckTypeGroup').then(rp => {
        // console.log(rp)
        var data = rp.data
        for (let index = 0; index < data.length; index++) {
          var row = data[index]
          let rowdata = {
            id_group: row.id_group,
            name_group_en: row.name_group_en,
            name_group_th: row.name_group_th,
            status: '',
          }
          this.dataTruckTypeGroup.push(JSON.parse(JSON.stringify(rowdata)))
          // that.dataTruckTypeGroup_ck[index] = ''
        }
        // console.log(that.dataTruckTypeGroup)
      })
    },
    truck_type_sel(data) {
      // console.log(data)
      // console.log(id)
      // console.log(this.dataTruckType)
      this.data_truck_type_sel = []
      for (let index = 0; index < this.dataTruckType.length; index++) {
        const element = this.dataTruckType[index]
        var truck_type_name = {
          name: '',
          id_model: '',
        }
        if (element.status == 'checked') {
          if (this.goods_lang) {
            truck_type_name.name = element.name_model_th
          } else {
            truck_type_name.name = element.name_model_en
          }
          truck_type_name.id_model = element.id_model
          this.data_truck_type_sel.push(truck_type_name)
          this.dataTruckTypeGroup[element.id_group - 1].status = 'checked'
        }
      }
      this.checkTruckTypeInGroup(data)
    },
    async truck_type_group_sel(data, id) {
      // console.log(data)
      // console.log(id)
      // console.log(this.dataTruckTypeGroup)
      if (this.dataTruckTypeGroup[id].status == 'checked') {
        if (this.goods_lang) {
          this.data_truck_type_group_sel = data.name_group_th
        } else {
          this.data_truck_type_group_sel = data.name_group_en
        }
        await this.selectAllTruckTypeFromGroup(id, 'select')
      } else {
        this.data_truck_type_group_sel = ''
        await this.selectAllTruckTypeFromGroup(id, 'deselect')
      }
      // this.data_truck_type_group_sel_id = data.id_group
      // this.calTotalServiceCost()
    },
    selectAllTruckTypeFromGroup(id, mode) {
      // เลือกประเภทรถในกลุ่มรถทั้งหมด
      var id_group_data = id + 1
      var select_mode = mode
      var data = this.dataTruckType
      if (select_mode == 'select') {
        for (let index = 0; index < data.length; index++) {
          const element = data[index]
          if (element.id_group == id_group_data) {
            if (this.dataTruckType[index].other_flag != '1') {
              this.dataTruckType[index].status = 'checked'
            }
          }
        }
      } else {
        for (let index2 = 0; index2 < data.length; index2++) {
          const rowData = data[index2]
          if (rowData.id_group == id_group_data) {
            this.dataTruckType[index2].status = ''
          }
        }
      }
      // this.createTextInputTruckType()
      this.truck_type_sel(this.dataTruckTypeGroup[id])
    },
    checkTruckTypeInGroup(data) {
      var check_id_group = data.id_group
      var checkCount = 0
      for (let index = 0; index < this.dataTruckType.length; index++) {
        const element = this.dataTruckType[index]
        if (element.id_group == check_id_group) {
          if (element.status == 'checked') {
            checkCount = 1
          }
        }
      }
      if (checkCount == 0) {
        this.dataTruckTypeGroup[check_id_group - 1].status = ''
      }
      this.sendTruckTypeData()
    },
    sendTruckTypeData() {
      var that = this
      that.$emit('sendTruckTypeData', this.dataTruckType, this.dataTruckTypeGroup)
    },
  },
}
</script>

<template>
  <div class="col-main-menu h-100vh">
    <div class="card">
      <div style="padding: 4%;">
        <div v-for="(option2, index2) in dataTruckTypeGroup" :key="index2">
          <b-row>
            <b-col class="d-flex align-items-center">
              <b-form-checkbox v-model="dataTruckTypeGroup[index2].status" value="checked" :name="'checkbox_' + option2.id_group + '_' + page_selcet" @change="truck_type_group_sel(option2, index2)">
                <template v-if="goods_lang">{{ option2.name_group_th }}</template>
                <template v-else>{{ option2.name_group_en }}</template>
              </b-form-checkbox>
            </b-col>
            <b-col class="d-flex align-items-start justify-content-end">
              <img v-if="option2.id_group == '1'" src="@/assets/images/truck_img/4_wheels.png" width="60px" />
              <img v-if="option2.id_group == '2'" src="@/assets/images/truck_img/6_wheels.png" width="100px" />
              <img v-if="option2.id_group == '3'" src="@/assets/images/truck_img/10_wheels.png" width="105px" />
              <img v-if="option2.id_group == '4'" src="@/assets/images/truck_img/trailer2.png" width="200px" />
              <img v-if="option2.id_group == '5'" src="@/assets/images/truck_img/trailer.png" width="150px" />
              <img v-if="option2.id_group == '6'" src="@/assets/images/truck_img/tow_truck.png" width="200px" />
            </b-col>
          </b-row>
          <br />
          <b-row>
            <template v-for="(option, index) in dataTruckType">
              <b-col v-if="option.other_flag == '1' && option.id_group == option2.id_group" :key="option.id_model" lg="12">
                <b-form-checkbox v-model="dataTruckType[index].status" value="checked" :name="'checkbox_' + option.id_model + '_' + page_selcet" class="check-col" @change="truck_type_sel(option)">
                  <template v-if="goods_lang">{{ option.name_model_th }}</template>
                  <template v-else>{{ option.name_model_en }}</template>
                  <!-- <template>{{ dataTruckType[option.id_model - 1].status }}</template> -->
                </b-form-checkbox>
                <!-- <b-form-input id="truck_type_other" v-model="dataTruckType_note[index2]" type="text" class="form-control" :style="{ display: dataTruckType[index].status == 'checked' ? 'block' : 'none' }" /> -->
              </b-col>
              <b-col v-else-if="option.other_flag == '0' && option.id_group == option2.id_group" :key="option.id_model" lg="6">
                <b-form-checkbox v-model="dataTruckType[index].status" value="checked" class="check-col" :name="'checkbox_' + option.id_model + '_' + page_selcet" @change="truck_type_sel(option)">
                  <template v-if="goods_lang">{{ option.name_model_th }}</template>
                  <template v-else>{{ option.name_model_en }}</template>
                  <!-- <template>{{ dataTruckType[option.id_model - 1].status }}</template> -->
                </b-form-checkbox>
              </b-col>
            </template>
          </b-row>
          <hr />
          <br />
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.modal-detail-body-custom {
  padding: 1rem 1rem;
}
.modal-scrollbar {
  overflow: hidden scroll;
}
.modal-scrollbar {
  overflow: auto;
}
hr {
  border: 1px solid #e4e4e4;
}
.check-col {
  margin-bottom: 1rem;
}
</style>
