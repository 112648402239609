<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import Layout from '@/views/layouts/main2'
import simplebar from 'simplebar-vue'
import service from '@/server/http_service'
import i18n from '@/i18n'
import DatePicker from 'vue2-datepicker'
import global_func from '@/global_function'
import moment from 'moment'
import Swal from 'sweetalert2'
import DisplayLocation from '@/components/location/display_start_end_location'
import RighrMap from '@/components/location/right_map'
import ProvinceComp from '@/components/select_components/select_prov'
import TruckTypeComp from '@/components/select_components/select_truckType'
import PlanDetail from '@/components/modal/plans_detail'
import ModalCompany from '@/components/modal/company'
/**
 * Starter page component
 */
export default {
  page: {
    title: 'ค้นหารถว่าง',
    meta: [{ name: 'description' }],
  },
  components: { Layout, simplebar, DatePicker, DisplayLocation, RighrMap, ProvinceComp, TruckTypeComp, PlanDetail, ModalCompany },
  data() {
    let lang = localStorage.getItem('account_lang')
    let user = localStorage.getItem('users')
    let province_local = localStorage.getItem('province')
    let trucktype_local = localStorage.getItem('trucktype')
    var lang_obj = i18n.t('plans_carrier_display')
    return {
      //genaral
      account_lang: lang,
      account_data: JSON.parse(user),
      province_list: JSON.parse(province_local),
      trucktype_list: JSON.parse(trucktype_local),
      check_lang: true,
      lang: lang_obj,
      // goods data
      dataGoodsType_master: [],
      dataGoodsType_data: [],
      // truck data
      dataTruckType: [],
      dataTruckTypeGroup: [],
      // plans data
      plans_data: [],
      plans_data_status: true,
      plans_row_data: [], // ข้อมูลที่แสดงในแถวแผนงาน
      plans_display_status: false,
      plan_data_selcet: [],

      // plan display
      activeArrow: [],
      active_tab: 0,
      service_provider_raw_data: [],
      estimate_raw_data: [],
      estimate_data: [],
      baggage_raw_data: [],

      data_service_etc: [
        {
          service_etc_id: 1,
          name_th: 'พนักงานช่วยขนของ',
          name_en: 'พนักงานช่วยขนของ2',
          service_sel_status: '',
        },
        {
          service_etc_id: 2,
          name_th: 'พนักงานเพิ่มเติม',
          name_en: 'พนักงานเพิ่มเติม2',
          service_sel_status: '',
        },
        {
          service_etc_id: 3,
          name_th: 'บริการพาเลทรองพื้น',
          name_en: 'บริการพาเลทรองพื้น2',
          service_sel_status: '',
        },
        {
          service_etc_id: 4,
          name_th: 'อุปกรณ์ช่วยขนของ',
          name_en: 'อุปกรณ์ช่วยขนของ2',
          service_sel_status: '',
        },
        {
          service_etc_id: 5,
          name_th: 'อื่นๆ',
          name_en: 'อื่นๆ2',
          service_sel_status: '',
        },
      ],

      // date_search: [new Date(), new Date()],
      date_search: [],
      start_date: '',
      end_date: '',
      province_departure: '',
      province_arrival: '',
      truck_type: '',
      selectedDeparture: '',
      selectedArrival: '',
      selectedTruckType: '',
      obj_sel_depart: {},
      obj_sel_arrive: {},
      selectedIndexOrder: '',

      plans_data_order: [],
      order_offer_data: [],
      order_offer_status: false,

      subscribe_data : [],
    }
  },
  watch: {},
  mounted() {
    this.getDataGoodsType()
    this.getTruckType()
    this.getPlansData()
    this.getSubscribeData()
  },
  methods: {
    // --- get data part ---
    getDataGoodsType() {
      // get ข้อมูล ประเภทสินค้า
      service.postService('getGoodsType').then(rp => {
        // console.log(rp)
        let data = rp.data
        for (let index = 0; index < data.length; index++) {
          var goods_type_sel = {
            goods_type_id: data[index]['goods_type_id'],
            goods_type_name_th: data[index]['goods_type_name_th'],
            goods_type_name_en: data[index]['goods_type_name_en'],
            goods_type_status: '',
          }
          var obj2 = JSON.stringify(goods_type_sel)
          var obj3 = JSON.parse(obj2)
          this.dataGoodsType_master.push(obj3) // for insert data to db
          this.dataGoodsType_data.push(obj3)
          if (this.account_lang != 'th') {
            this.check_lang = false
          }
        }
        // console.log(this.dataGoodsType_data)
      })
    },
    getTruckType() {
      // get ประเภทรถบรรทุก
      service.postService('getTruckType').then(rp => {
        // console.log(rp)
        this.dataTruckType = rp.data
        // console.log(this.dataTruckType)
      })
    },
    getPlansData() {
      // get ข้อมูล แผนงานขนส่ง
      var that = this
      service
        .postService('searchCarrier', {
          mode: 'getData',
          start: this.start_date,
          end: this.end_date,
          user_data: this.account_data,
          prov_depart: this.province_departure,
          prov_arrive: this.province_arrival,
          truck_type: this.truck_type,
        })
        .then(rp => {
          // console.log(rp)
          if(rp.status == 'ok') {
            this.plans_data = rp.data
          } else {
            this.plans_data = []
          }
          setTimeout(() => {
            that.createRowOfPlans()
          }, 300)
        })
    },
    getOfferData(carrier) {
      service
        .postService('getPlanOrderOffer', {
          user_data: this.account_data,
          id_carrier: carrier.rawData.id_carrier,
        })
        .then(rp => {
          setTimeout(() => {
            this.createRowOffer(rp.dataOrder, rp.dataOffer)
          }, 300)
        })
    },
    // create plans display
    async createRowOfPlans() {
      var ck1 = this.dataTruckType.length > 0
      var ck2 = this.plans_data.length > 0
      this.plans_row_data = []
      if (ck1 && ck2) {
        var rowPlanData = []
        var all_provider_id = []
        for (let index = 0; index < this.plans_data.length; index++) {
          const row = this.plans_data[index]
          // console.log(row)
          let pickup_time = this.getPickupTime(row.pickup_time_1, row.pickup_time_2)
          let vehicle_type_obj = row.vehicle_type_id.split('|')
          var truck_type_name = this.dataTruckType[vehicle_type_obj[0] - 1].name_model_th
          var truck_type_group = this.dataTruckType[vehicle_type_obj[0] - 1].id_group
          if (vehicle_type_obj.length > 1) {
            truck_type_name = truck_type_name + ', ...'
          }
          let data = {}
          data = {
            truck_type_group: truck_type_group,
            truck_type_name: truck_type_name,
            status_name: this.getPlanStatus(row.carrier_status),
            star_flag: this.findStarFlag(row.provider_id),
            star_score: row.user_score.star_score,
            lover_score: row.user_score.lover_score,
            count_offer: parseInt(row.count_offer),
            provider_id: row.provider_id,
            rawData: row,
          }
          
          rowPlanData.push(data)
          all_provider_id.push(row.provider_id)
        }
        
        this.plans_row_data = await this.bindingProviderStar(rowPlanData)
        // console.log(this.plans_row_data)
        this.plans_data_status = true
        let that = this
        setTimeout(function() {
          that.getAllProfilePicture(all_provider_id)
        }, 500)
      } else {
        this.plans_data_status = false
      }
    },
    createRowOffer(order, offer) {
      //order
      this.plans_data_order = []
      for (let index = 0; index < order.length; index++) {
        let curr_data = order[index]
        var expected_price = curr_data.expected_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        var good_type_name = this.getGoodsTypeName(curr_data.goods_type_id)
        let vehicle_type_obj = curr_data.vehicle_type_id.split('|')
        var truck_type_name2 = this.dataTruckType[vehicle_type_obj[0] - 1].name_model_th
        var vehicle_type_group_id = this.dataTruckType[vehicle_type_obj[0] - 1].id_group //vehicle_type_obj[0]
        //var truck_type_group = this.dataTruckType[vehicle_type_obj[0]].id_group
        if (vehicle_type_obj.length > 1) {
          truck_type_name2 = truck_type_name2 + ', ...'
        }

        var order_period_display = this.split_period_display(curr_data.order_period)

        var split_other_service = curr_data.other_services.split('|')
        var other_service_display = ''
        for (var i = 0; i < split_other_service.length; i++) {
          for (var k = 0; k < this.data_service_etc.length; k++) {
            if (split_other_service[i] == this.data_service_etc[k].service_etc_id) {
              if (other_service_display != '') {
                other_service_display += ','
              }
              other_service_display += this.data_service_etc[k].name_th
            }
          }
        }

        var data_push = {
          id_order_tracking: curr_data.id_order_tracking,

          id_order: curr_data.id_order,
          id_member_goods_owner: curr_data.id_member,
          provider_id: curr_data.provider_id,
          provider_name: curr_data.mover_name,
          goods_type_id: '1', //element.goods_type_id
          provider_status_code: '',
          provider_status_name: 'ไม่มีรถบริการในเวลาดังกล่าว',
          provider_tel: curr_data.mover_tel,
          provider_email: curr_data.mailaddr,
          mover_address1: '',
          provider_plan_action_code: '',
          star_score: '-',
          lover_score: '-',
          expected_price: expected_price,

          departure_address: curr_data.departure_address,
          departure_lat_lon: curr_data.departure_lat_lon,

          arrival_address: curr_data.arrival_address,
          arrival_lat_lon: curr_data.arrival_lat_lon,

          total_distance: curr_data.total_distance,
          good_type_id: curr_data.goods_type_id,
          good_type_name: good_type_name,
          question: curr_data.question,
          quantity: curr_data.quantity,
          truck_type_name: truck_type_name2,
          vehicle_type_group_id: vehicle_type_group_id,
          other_services: curr_data.other_services,
          other_service_display: other_service_display,
          //truck_type_group: truck_type_group,
          created: curr_data.created,
          order_period: curr_data.order_period,
          order_period_display: order_period_display,
          active_status: false,
          rawData: curr_data,
        }
        this.plans_data_order.push(data_push)
      }

      //offer
      this.order_offer_data = []
      for (let index = 0; index < offer.length; index++) {
        let curr_data = offer[index]
        var expected_price2 = curr_data.expected_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        var good_type_name2 = this.getGoodsTypeName(curr_data.goods_type_id)
        var vehicle_type_obj2 = curr_data.vehicle_type_id.split('|')
        var truck_type_name = this.dataTruckType[vehicle_type_obj2[0] - 1].name_model_th
        var vehicle_type_group_id2 = this.dataTruckType[vehicle_type_obj2[0] - 1].id_group
        if (vehicle_type_obj2.length > 1) {
          truck_type_name = truck_type_name + ', ...'
        }

        var order_period_display2 = this.split_period_display(curr_data.order_period)

        // var split_other_service = curr_data.other_services.split('|')
        // var other_service_display = ''
        // for (var i = 0; i < split_other_service.length; i++) {
        //   for (var k = 0; k < this.data_service_etc.length; k++) {
        //     if (split_other_service[i] == this.data_service_etc[k].service_etc_id) {
        //       if (other_service_display != '') {
        //         other_service_display += ','
        //       }
        //       other_service_display += this.data_service_etc[k].name_th
        //     }
        //   }
        // }

        var data_push2 = {
          id_order_tracking: curr_data.id_order_tracking,

          id_order: curr_data.id_order,
          id_member_goods_owner: curr_data.id_member,
          provider_id: curr_data.provider_id,
          provider_name: curr_data.mover_name,
          goods_type_id: '1', //element.goods_type_id
          provider_status_code: '',
          provider_status_name: 'ไม่มีรถบริการในเวลาดังกล่าว',
          provider_tel: curr_data.mover_tel,
          provider_email: curr_data.mailaddr,
          mover_address1: '',
          provider_plan_action_code: '',
          star_score: '-',
          lover_score: '-',
          expected_price: expected_price2,

          departure_address: curr_data.departure_address,
          departure_lat_lon: curr_data.departure_lat_lon,

          arrival_address: curr_data.arrival_address,
          arrival_lat_lon: curr_data.arrival_lat_lon,

          total_distance: curr_data.total_distance,
          good_type_id: curr_data.goods_type_id,
          good_type_name: good_type_name2,
          question: curr_data.question,
          quantity: curr_data.quantity,
          truck_type_name: truck_type_name,
          vehicle_type_group_id: vehicle_type_group_id2,
          // other_services: curr_data.other_services,
          // other_service_display: other_service_display,
          created: curr_data.created,
          order_period: curr_data.order_period,
          order_period_display: order_period_display2,
          active_status: false,
          rawData: curr_data,
        }
        this.order_offer_data.push(data_push2)
      }
      if (offer.length > 0) {
        this.order_offer_status = false
      } else {
        this.order_offer_status = true
      }
    },
    // action part
    click_select_depart() {
      var that = this
      that.$bvModal.show('modal_prov_depart')
    },
    click_select_arrive() {
      var that = this
      that.$bvModal.show('modal_prov_arrive')
    },
    search_carier() {
      if(this.date_search.length > 0) {
        if(this.date_search[0] != '' && this.date_search[1] != '') {
          this.start_date = moment(this.date_search[0]).format('YYYY-MM-DD')
          this.end_date = moment(this.date_search[1]).format('YYYY-MM-DD')
        } else {
          this.date_search[0] = ''
          this.date_search[1] = ''
        }
      } else {
        this.date_search[0] = ''
        this.date_search[1] = ''
      }
      this.getPlansData()
    },
    activeSelectTruckType() {
      this.$bvModal.show('modal_select_truckType')
    },
    bt_company_detail(data) {
      var that = this
      // that.$bvModal.show('modal_company_detail')
      var param_send = { provider_id: data.provider_id }
      this.$refs.refCompany.setProviderData(param_send)
      // console.log(that.$refs.refCompany.setProviderData(data))
    },
    getTruckType_select: function(trucktype, trucktypegroup) {
      this.dataTruckType = trucktype
      this.dataTruckTypeGroup = trucktypegroup
      // console.log(this.dataTruckType)
      // console.log(this.dataTruckTypeGroup)
      this.truck_type = ''
      this.selectedTruckType = ''

      var len_tt = this.dataTruckType.length
      var text_tt = ''
      var id_tt = ''
      for (var i = 0; i < len_tt; i++) {
        var tt = this.dataTruckType[i]
        if (tt.status == 'checked') {
          text_tt += tt.name_model_th + ','
          id_tt += tt.id_model + ','
        }
      }
      if (text_tt != '') {
        this.truck_type = id_tt.slice(0, -1)
        text_tt = text_tt.slice(0, -1)
        var arr_tt = text_tt.split(',')
        var len = arr_tt.length
        if (len > 2) {
          this.selectedTruckType = arr_tt[0] + ' ,' + arr_tt[1] + ', ' + '+' + parseInt(len - 2)
        } else {
          for (var j = 0; j < len; j++) {
            if (j > 0) {
              this.selectedTruckType += ', '
            }
            this.selectedTruckType += arr_tt[j]
          }
        }
      }
    },
    select_prov_depart: function(data) {
      // console.log(data)
      this.obj_sel_depart = data
      this.binding_data_prov_depart()
    },
    select_prov_arrive: function(data) {
      // console.log(data)
      this.obj_sel_arrive = data
      this.binding_data_prov_arrive()
    },
    activePlansDisplay(planData) {
      this.plan_data_selcet = planData

      if (this.plans_display_status) {
        this.plans_display_status = false
      } else {
        this.plans_display_status = true
        this.getOfferData(planData)

        this.plan_location = []
        this.plan_location[0] = {
          name: planData.rawData.depart_address1,
          date_appointment: planData.rawData.depart_date,
        }
        this.plan_location[1] = {
          name: planData.rawData.arrive_address1,
          date_appointment: planData.rawData.arrive_date,
        }
        var self = this
        setTimeout(function() {
          self.$refs.location_display.show(self.plan_location)
        }, 500)
      }
    },
    modal_propose() {
      this.selectedIndexOrder = ''
      this.$bvModal.show('modal_propose_plan')
    },
    open_modal_detail(data) {
      this.$refs.plandetail.bt_detail(data)
    },
    routersClick(link) {
      this.$router.push({ path: link })
    },
    selectPlanSendOffer(index) {
      this.selectedIndexOrder = index
    },
    // binding and convert raw data
    getGoodsTypeName(goodsTypeId) {
      // นำ goods_type_id มาหาชื่อของประเภทสินค้า
      var data_return = ''
      if (goodsTypeId != null && goodsTypeId != '') {
        var res = goodsTypeId.split('|')
        var count_goods_type = 0
        // console.log(res)
        // console.log(this.dataGoodsType_data)
        for (let index2 = 0; index2 < res.length; index2++) {
          var goodsType_id = res[index2]
          if (count_goods_type < 2) {
            for (let index = 0; index < this.dataGoodsType_data.length; index++) {
              const element = this.dataGoodsType_data[index]
              if (element.goods_type_id == goodsType_id) {
                if (data_return != '') {
                  data_return += ', '
                  data_return += element.goods_type_name_th
                  if (!this.check_lang) {
                    data_return += element.goods_type_name_en
                  }
                } else {
                  data_return = element.goods_type_name_th
                  if (!this.check_lang) {
                    data_return += element.goods_type_name_en
                  }
                }
              }
            }
            count_goods_type++
          } else {
            data_return += ', ...'
          }
        }
      } else {
        data_return = '-'
      }
      // console.log(data_return)
      return data_return
    },
    getPickupTime(time1, time2) {
      var dateData = global_func.Between2Date(time1, time2)
      var diffTime = dateData.diffTime / 1000 / 60 / 60
      var date_return = ''
      if (diffTime > 23.9997222222 && diffTime < 24) {
        date_return = moment(time1).format('dd DD/MM/YYYY') + ' ' + '(ตลอดทั้งวัน)'
      } else {
        date_return = moment(time1).format('dd DD/MM/YYYY HH:mm') + ' - ' + moment(time2).format('dd DD/MM/YYYY HH:mm')
      }
      return date_return
    },
    binding_data_prov_depart() {
      this.province_departure = ''
      this.selectedDeparture = ''
      if (Object.keys(this.obj_sel_depart).length) {
        var p1 = this.loop_geo_checked(this.obj_sel_depart[1])
        this.province_departure += p1
        var p2 = this.loop_geo_checked(this.obj_sel_depart[2])
        this.province_departure += p2
        var p3 = this.loop_geo_checked(this.obj_sel_depart[3])
        this.province_departure += p3
        var p4 = this.loop_geo_checked(this.obj_sel_depart[4])
        this.province_departure += p4
        var p5 = this.loop_geo_checked(this.obj_sel_depart[5])
        this.province_departure += p5
        var p6 = this.loop_geo_checked(this.obj_sel_depart[6])
        this.province_departure += p6
      } else {
        this.province_departure = ''
      }
      if (this.province_departure.length > 0) {
        this.province_departure = this.province_departure.slice(0, -1)
        var arr_prov = this.province_departure.split(',')
        var len_prov_dep = arr_prov.length
        if (len_prov_dep > 2) {
          this.selectedDeparture = arr_prov[0] + ' ,' + arr_prov[1] + ', ' + '+' + parseInt(len_prov_dep - 2)
        } else {
          for (var i = 0; i < len_prov_dep; i++) {
            if (i > 0) {
              this.selectedDeparture += ', '
            }
            this.selectedDeparture += arr_prov[i]
          }
        }
      }
    },
    binding_data_prov_arrive() {
      this.province_arrival = ''
      this.selectedArrival = ''
      if (Object.keys(this.obj_sel_arrive).length) {
        var p1 = this.loop_geo_checked(this.obj_sel_arrive[1])
        this.province_arrival += p1
        var p2 = this.loop_geo_checked(this.obj_sel_arrive[2])
        this.province_arrival += p2
        var p3 = this.loop_geo_checked(this.obj_sel_arrive[3])
        this.province_arrival += p3
        var p4 = this.loop_geo_checked(this.obj_sel_arrive[4])
        this.province_arrival += p4
        var p5 = this.loop_geo_checked(this.obj_sel_arrive[5])
        this.province_arrival += p5
        var p6 = this.loop_geo_checked(this.obj_sel_arrive[6])
        this.province_arrival += p6
      } else {
        this.province_arrival = ''
      }
      if (this.province_arrival.length > 0) {
        this.province_arrival = this.province_arrival.slice(0, -1)
        var arr_prov = this.province_arrival.split(',')
        var len_prov = arr_prov.length
        if (len_prov > 2) {
          this.selectedArrival = arr_prov[0] + ' ,' + arr_prov[1] + ', ' + '+' + parseInt(len_prov - 2)
        } else {
          for (var i = 0; i < len_prov; i++) {
            if (i > 0) {
              this.selectedArrival += ', '
            }
            this.selectedArrival += arr_prov[i]
          }
        }
      }
    },
    loop_geo_checked(data) {
      var text = ''
      for (var i = 0; i < data.length; i++) {
        var d = data[i]
        if (d.id != '101' && d.id != '102' && d.id != '103' && d.id != '104' && d.id != '105' && d.id != '106') {
          if (d.status == 'checked') {
            text += d.name_th + ','
          }
        }
      }
      return text
    },
    getPlanStatus(carrier_status) {
      var data_return = ''
      if (carrier_status == '0' || carrier_status == '1') {
        data_return = 'รอเสนองาน'
      } else if (carrier_status == '2') {
        data_return = 'รอดำเนินการ'
      } else if (carrier_status == '3') {
        data_return = 'อยู่ระหว่างขนส่ง'
      } else if (carrier_status == '4') {
        data_return = 'ดำเนินการเสร็จแล้ว'
      } else if (carrier_status == '5') {
        data_return = 'ปิดงาน'
      }
      return data_return
    },
    getDateTime(datetime) {
      // แปลงเวลาเป็น format สำหรับแสดงบนเว็บ
      var ck1 = datetime !== null && datetime != null && datetime != undefined
      var data_return = this.lang.no_data_time
      if (ck1) {
        data_return = moment(datetime).format('dd DD/MM/YYYY HH:mm')
      }
      return data_return
    },
    split_period_display(order_period) {
      var split_period = order_period.split(' ')
      var year_period = split_period[0].split('-')[0]
      year_period = year_period.substr(year_period.length - 2, 2)

      var month_period = split_period[0].split('-')[1]
      var date_period = split_period[0].split('-')[2]

      var hour_period = split_period[1].split(':')[0]
      var minute_period = split_period[1].split(':')[1]

      var dt = new Date(order_period)
      var list_short_date = [
        { th: 'อา', en: 'Sun' },
        { th: 'จ', en: 'Mon' },
        { th: 'อ', en: 'Tue' },
        { th: 'พ', en: 'Wed' },
        { th: 'พฤ', en: 'Thu' },
        { th: 'ศ', en: 'Fri' },
        { th: 'ส', en: 'Sat' },
      ]
      var order_period_display = list_short_date[dt.getDay()][localStorage.getItem('account_lang')] + '. ' + date_period + '/' + month_period + '/' + year_period + ' ' + hour_period + '.' + minute_period + 'น.'
      return order_period_display
    },
    findTwoPoint(p1, p2) {
      let point1_split = []
      let point2_split = []
      let data_return = ''
      if (p1 != undefined && p1 != null) {
        let point1 = p1.trim()
        point1_split = point1.split(' ')
        if (point1_split.length > 0) {
          let ck_str = point1_split[point1_split.length - 1]
          let index = 1
          if (!isNaN(ck_str)) {
            index++
          }
          let prov = point1_split[point1_split.length - index]
          let prov_split = prov.split('.')
          if (prov_split.length > 1) {
            data_return += prov_split[1]
          } else {
            data_return += prov_split[0]
          }
        }
      }
      if (p2 != undefined || p2 != null) {
        let point2 = p2.trim()
        point2_split = point2.split(' ')
        if (point2_split.length > 0) {
          let ck_str = point2_split[point2_split.length - 1]
          if (data_return != '') {
            data_return += ' - '
          }
          let index = 1
          if (!isNaN(ck_str)) {
            index++
          }
          let prov = point2_split[point2_split.length - index]
          let prov_split = prov.split('.')
          if (prov_split.length > 1) {
            data_return += prov_split[1]
          } else {
            data_return += prov_split[0]
          }
        }
      }
      return data_return
    },
    // ask and send data
    sent_propose() {
      var index = this.selectedIndexOrder

      if (index !== '') {
        var order = this.plans_data_order[index]
        var carrier = this.plan_data_selcet
        // console.log(carrier)
        // console.log(order)
        this.askSendAlert(carrier, order)
      } else {
        // console.log('กรุณาเลือกแผนงาน')
      }
    },
    cancel_propose() {
      this.selectedIndexOrder = ''
      this.$bvModal.hide('modal_propose_plan')
    },
    askSendAlert(carrier, order) {
      Swal.fire({
        title: 'คุณยืนยันที่จะ “เสนองาน” ใช่หรือไม่',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        allowOutsideClick: true,
        confirmButtonColor: '#34c38f',
        confirmButtonText: 'ใช่ ฉันยืนยัน',
        cancelButtonText: 'ยกเลิก',
      }).then(result => {
        if (result.value) {
          this.sendPlanOfferToCarrier(carrier, order)
        } else {
          return false
        }
      })
    },
    sendPlanOfferToCarrier(carrier, order) {
      var that = this
      service
        .postService('sendPlanOfferToCarrier', {
          provider_data: carrier,
          plan_data: order,
        })
        .then(rp => {
          // console.log(rp)
          global_func.alertMessage('ยื่นข้อเสนอเรียบร้อย', '', 'success')
          that.$bvModal.hide('modal_propose_plan')
          setTimeout(function() {
            that.getOfferData(carrier)
          }, 500)
        })
    },
    askCanclePropose(order) {
      var carrier = this.plan_data_selcet
      // console.log(order)
      Swal.fire({
        title: 'คุณยืนยันที่จะ ยกเลิกการเสนองาน ใช่หรือไม่',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        allowOutsideClick: true,
        confirmButtonColor: '#34c38f',
        confirmButtonText: 'ใช่ ฉันยืนยัน',
        cancelButtonText: 'ยกเลิก',
      }).then(result => {
        if (result.value) {
          this.delPlanOffer(carrier, order)
        } else {
          return false
        }
      })
    },
    delPlanOffer(carrier, order) {
      var that = this
      service
        .postService('delPlanOffer', {
          provider_data: carrier,
          plan_data: order,
        })
        .then(rp => {
          // console.log(rp)
          global_func.alertMessage('ยกเลิกการยื่นข้อเสนอเรียบร้อย', '', 'success')
          setTimeout(function() {
            that.getOfferData(carrier)
          }, 500)
        })
    },
    updateStar(val, index){
      // console.log(val)
      var that = this
      let mode = 'unsub'
      if(val.star_flag == '0') {
        mode = 'sub'
      }
      if(typeof index !== 'undefined') {
        if (this.plans_row_data[index].star_flag == '0') {
          this.plans_row_data[index].star_flag = '1'
        } else {
          this.plans_row_data[index].star_flag = '0'
        }
      } else {
        for (let index2 = 0; index2 < this.plans_row_data.length; index2++) {
          const element = this.plans_row_data[index2]
          if(val.rawData.id_carrier_tracking == element.rawData.id_carrier_tracking) {
            if (this.plans_row_data[index2].star_flag == '0') {
              this.plans_row_data[index2].star_flag = '1'
              this.plan_data_selcet.star_flag = '1'
            } else {
              this.plans_row_data[index2].star_flag = '0'
              this.plan_data_selcet.star_flag = '0'
            }
          }
        }
      }

      service
        .postService('updateSubscribe', {
          account_data : this.account_data,
          provider_id : val.provider_id,
          mode : mode,
        })
        .then(rp => {
          if(rp.code == '200'){
            this.getSubscribeData()
            // setTimeout(() => {
            //   that.getPlansData()
            // }, 100)
          }
        })
    },
    getSubscribeData() {
      service
        .postService('getSubscribeData', {
          account_data : this.account_data,
        })
        .then(rp => {
          // console.log(rp)
          if(rp.status == 'ok') {
            this.subscribe_data = []
            this.subscribe_data = rp.data
          }
          // console.log(this.subscribe_data)
        })
    },
    findStarFlag(id_member) {
      let subscribe_data = this.subscribe_data
      let flag = '0'
      for (let index = 0; index < subscribe_data.length; index++) {
        const element = subscribe_data[index]
        if(element.provider_id == id_member) {
          flag = '1'
        }
      }
      return flag
    },
    bindingProviderStar(data) {
      let provider_data = data
      let provider_data_1 = []
      let provider_data_2 = []
      let provider_data_return = []

      // star first
      for (let index = 0; index < provider_data.length; index++) {
        const element = provider_data[index]
        if(element.star_flag == '1') {
          provider_data_1.push(element)
        } else {
          provider_data_2.push(element)
        }
      }

      for (let index2 = 0; index2 < provider_data_1.length; index2++) {
        const element = provider_data_1[index2]
        provider_data_return.push(element)
      }

      for (let index3 = 0; index3 < provider_data_2.length; index3++) {
        const element = provider_data_2[index3]
        provider_data_return.push(element)
      }

      return provider_data_return
      
    },
    askCreateSpecificPlans() {
      var carrier = this.plan_data_selcet
      // console.log(order)
      Swal.fire({
        title: 'คุณยืนยันที่จะสร้าง \nแผนงานเฉพาะ \nใช่หรือไม่',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        allowOutsideClick: true,
        confirmButtonColor: '#34c38f',
        confirmButtonText: 'ใช่ ฉันยืนยัน',
        cancelButtonText: 'ยกเลิก',
      }).then(result => {
        if (result.value) {
          this.createSpecificPlans(carrier)
        } else {
          return false
        }
      })
    },
    createSpecificPlans(data) {
      let carrier_plan = data
      // console.log(carrier_plan)
      sessionStorage.setItem('specific_goods_plan', JSON.stringify(carrier_plan))
      this.routersClick('/transportation_plan/create')
    },
    getAllProfilePicture(all_provider_id) {
      let provider_id = [...new Set(all_provider_id)]
      // console.log(provider_id)
      service
        .postService('getAllProfilePicture', {
          id_member_data: provider_id,
        })
        .then(rp => {
          // console.log(rp)
          this.setProfilePicture(rp.data)
        })
    },
    setProfilePicture(profile_picture_data) {
      let temp_plans_row_data = this.plans_row_data
      this.plans_row_data = []
      for (let index = 0; index < temp_plans_row_data.length; index++) {
        let element = temp_plans_row_data[index]
        // console.log(element)
        const blob = profile_picture_data.filter((e) => {
          return e.id_member == element.provider_id
        })
        element.pic_profile = blob[0].pic_profile
        this.plans_row_data.push(element)
      }
      // console.log('plans_row_data', this.plans_row_data)
    },
    routersClick2(value) {
      // eslint-disable-next-line no-undef
      let link = 'plans_display'
      this.$router.push({ path: link + `/?data=${value.id_order_tracking}` })
    },
  },
}
</script>

<template>
  <Layout>
    <b-row>
      <b-col>
        <transition name="bounce" enter-active-class="fadeInLeft" leave-active-class="fadeOutLeft">
          <div v-if="plans_display_status" class="col-main-menu h-100vh summary-of-plan" :class="{ active: plans_display_status }">
            <div class="card" style="max-height: calc(100vh - 141px); min-height: calc(100vh - 141px);">
              <b-row>
                <b-col>
                  <div class="card-body" style="max-height: calc(100vh - 141px); min-height: calc(100vh - 141px);">
                    <div style="padding: 1% 1% 0 1%" class="font-size-18">
                      <b-row class="col-margin-bot-1">
                        <b-col lg="3">
                          <span class="d-flex justify-content-left btn_back_blue" @click="activePlansDisplay()"><i class="ri-arrow-left-line" style="margin-right: 5px" />ย้อนกลับ</span>
                        </b-col>
                      </b-row>
                      <b-row class="col-margin-bot-1">
                        <b-col>
                          <span class="plans_detail_title">
                            {{ lang.plans_detail_display.title }}
                          </span>
                        </b-col>
                      </b-row>
                      <simplebar class="card-body" style="max-height: calc(100vh - 280px); min-height: calc(100vh - 280px); padding: 0px 20px 0px 0px">
                        <b-row class="col-margin-bot-1">
                          <b-col lg="2" class="d-flex align-items-center">
                            <!-- <img src="@/assets/images/plans_display/star_empty.png" width="24px" height="24px" style="margin-right: 1rem; margin-left: 0.6rem" /> -->
                            <i :class="plan_data_selcet.star_flag == '1' ? 'ri-star-fill': 'ri-star-line'" :style="plan_data_selcet.star_flag == '1' ? 'color:#FAAB00;cursor:pointer;font-size:25px;margin-right: 0.6rem;' : 'cursor:pointer;font-size:25px;margin-right: 0.6rem;'" @click="updateStar(plan_data_selcet)" />
                            <img v-if="plan_data_selcet.pic_profile" :src="plan_data_selcet.pic_profile" width="40px" height="40px" class="rounded-circle avatar-md" />
                            <img v-else src="@/assets/images/plans_display/Group 3361@2x.png" width="40px" height="40px" class="rounded-circle avatar-md" />
                          </b-col>
                          <b-col lg="10">
                            <b-row>
                              <b-col>
                                <span class="font-size-16">
                                  {{ plan_data_selcet.rawData.mover_name_carrier }}
                                </span>
                              </b-col>
                            </b-row>
                            <b-row class="col-margin-bot-1">
                              <b-col>
                                <span class="font-size-14" style="margin-right: 0.5rem"> ผู้สนใจ/Rating : </span>
                                <img src="@/assets/images/plans_display/star@2x.png" width="16px" height="16px" />
                                <span class="font-size-14" style="margin-right: 0.5rem"> ( {{ plan_data_selcet.star_score }} ) </span>
                                <img src="@/assets/images/plans_display/lover.png" width="16px" height="16px" />
                                <span class="font-size-14"> ( {{ plan_data_selcet.lover_score }} ) </span>
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>

                        <b-row class="col-margin-bot-1">
                          <b-col lg="3"> {{ $t('company_detail.tab_data.tel') }} : </b-col>
                          <b-col lg="9">
                            {{ plan_data_selcet.rawData.mover_tel }}
                          </b-col>
                        </b-row>
                        <b-row class="col-margin-bot-1">
                          <b-col lg="3"> {{ $t('company_detail.tab_data.email') }} : </b-col>
                          <b-col lg="9">
                            {{ (plan_data_selcet.rawData.mailaddr != 'null' && plan_data_selcet.rawData.mailaddr != NULL && plan_data_selcet.rawData.mailaddr != '')? plan_data_selcet.rawData.mailaddr : ' - ' }}
                          </b-col>
                        </b-row>
                        <b-row class="col-margin-bot-1">
                          <b-col lg="3"> {{ $t('company_detail.title') }} : </b-col>
                          <b-col lg="9">
                            <b-link class="font-size-16" href="#" @click="bt_company_detail(plan_data_selcet.rawData)">{{ $t('plans_display.provider_display.tab1.detail') }}</b-link>
                            <ModalCompany ref="refCompany" :obj="{}" />
                          </b-col>
                        </b-row>

                        <hr />

                        <b-row class="col-margin-bot-1">
                          <b-col lg="3">
                            <span class="plans_detail_topic">
                              {{ lang.plans_detail_display.code }}
                            </span>
                          </b-col>
                          <b-col lg="9">
                            <span class="font-color-blue">
                              {{ plan_data_selcet.rawData.id_carrier_tracking }}
                              <!-- id_order_tracking -->
                            </span>
                          </b-col>
                        </b-row>
                        <b-row class="col-margin-bot-1">
                          <b-col lg="3">
                            <span class="plans_detail_topic">
                              {{ lang.plans_detail_display.date_create }}
                            </span>
                          </b-col>
                          <b-col lg="9">
                            <span class="font-color-blue">
                              {{ plan_data_selcet.rawData.created | moment('dd DD/MM/YYYY HH:mm') }}
                            </span>
                          </b-col>
                        </b-row>

                        <b-row class="col-margin-bot-1">
                          <b-col lg="3">
                            <span class="plans_detail_topic"> {{ lang.plans_detail_display.status }} : </span>
                          </b-col>
                          <b-col lg="9">
                            <span class="plans-row-data-content font-color-blue">
                              {{ plan_data_selcet.status_name }}
                            </span>
                            <span class="plans-row-data-content font-color-red"> ( เสนอราคาถึงวันที่ {{ plan_data_selcet.rawData.period | moment('dd DD/MM/YYYY') }} ) </span>
                          </b-col>
                        </b-row>

                        <b-row class="col-margin-bot-1">
                          <b-col lg="3">
                            <span class="plans-row-data-content">
                              {{ lang.plans_detail_display.goods_type }}
                            </span>
                          </b-col>
                          <b-col lg="9">
                            <span class="plans-row-data-content font-color-blue">
                              {{ plan_data_selcet.goods_type_name_full }}
                            </span>
                          </b-col>
                        </b-row>

                        <b-row class="col-margin-bot-1">
                          <b-col lg="3">
                            <span class="plans-row-data-content">
                              {{ lang.plans_detail_display.location }}
                            </span>
                          </b-col>
                          <b-col lg="9">
                            <DisplayLocation ref="location_display" :arrlocation="plan_location" />
                          </b-col>
                        </b-row>
                        <b-row class="col-margin-bot-1">
                          <b-col lg="3">
                            <span class="plans-row-data-content">
                              {{ lang.plans_detail_display.total_distance }}
                            </span>
                          </b-col>
                          <b-col lg="9">
                            <span class="plans-row-data-content font-color-blue"> {{ plan_data_selcet.rawData.total_distance }} {{ lang.plans_detail_display.km }} </span>
                          </b-col>
                        </b-row>
                        <b-row class="col-margin-bot-1">
                          <b-col lg="3">
                            <span class="plans-row-data-content">
                              {{ lang.plans_detail_display.truck_type }}
                            </span>
                          </b-col>
                          <b-col lg="9">
                            <span class="plans-row-data-title">
                              <img v-if="plan_data_selcet.truck_type_group == '1'" src="@/assets/images/truck_img/4_wheels.png" width="60px" style="margin-right: 20px" />
                              <img v-else-if="plan_data_selcet.truck_type_group == '2'" src="@/assets/images/truck_img/6_wheels.png" width="60px" style="margin-right: 20px" />
                              <img v-else-if="plan_data_selcet.truck_type_group == '3'" src="@/assets/images/truck_img/10_wheels.png" width="60px" style="margin-right: 20px" />
                              <img v-else-if="plan_data_selcet.truck_type_group == '4'" src="@/assets/images/truck_img/trailer2.png" width="60px" style="margin-right: 20px" />
                              <img v-else-if="plan_data_selcet.truck_type_group == '5'" src="@/assets/images/truck_img/trailer.png" width="60px" style="margin-right: 20px" />
                              <img v-else-if="plan_data_selcet.truck_type_group == '6'" src="@/assets/images/truck_img/tow_truck.png" width="60px" style="margin-right: 20px" />
                              {{ plan_data_selcet.truck_type_name }}
                            </span>
                          </b-col>
                        </b-row>

                        <b-row class="col-margin-bot-1">
                          <b-col lg="3">
                            <span class="plans-row-data-content"> จำนวนรถ : </span>
                          </b-col>
                          <b-col lg="9">
                            <span v-if="plan_data_selcet.rawData.number_of_cars != '' && plan_data_selcet.rawData.number_of_cars !== null" class="plans-row-data-content font-color-blue"> {{ plan_data_selcet.rawData.number_of_cars }} คัน </span>
                            <span v-else class="plans-row-data-content font-color-blue"> - </span>
                          </b-col>
                        </b-row>

                        <b-row class="col-margin-bot-1">
                          <b-col lg="3">
                            <span class="plans-row-data-content"> น้ำหนักที่บรรทุกได้ : </span>
                          </b-col>
                          <b-col lg="9">
                            <span class="plans-row-data-content font-color-blue"> {{ plan_data_selcet.rawData.payload }} ตัน </span>
                          </b-col>
                        </b-row>

                        <b-row class="col-margin-bot-1">
                          <b-col lg="3">
                            <span class="plans-row-data-content"> หมายเหตุ : </span>
                          </b-col>
                          <b-col lg="9">
                            <span v-if="plan_data_selcet.rawData.note != '' && plan_data_selcet.rawData.note !== null" class="plans-row-data-content font-color-blue">
                              {{ plan_data_selcet.rawData.note }}
                            </span>
                            <span v-else class="plans-row-data-content"> - </span>
                          </b-col>
                        </b-row>

                        <hr />
                        <!-- <b-row class="col-margin-bot-1">
                          <b-col lg="6">
                            <b-button variant="primary" style="width: 100%" @click="modal_propose">เสนอแผนงานขนส่ง</b-button>
                          </b-col>
                          <b-col lg="6">
                            <button class="btn btn-outline-info" style="width: 100%">soon</button>
                          </b-col>
                        </b-row> -->

                        <!-- <b-row v-if="order_offer_status" class="col-margin-bot-1">
                          <b-col>
                            <span class="plans_detail_title"> แผนงานขนส่งที่มีการเสนอ </span>
                          </b-col>
                        </b-row> -->

                        <div v-if="order_offer_status">
                          <b-row class="col-margin-bot-1">
                            <b-col lg="6">
                              <div class="d-flex justify-content-center big_btn_blue font-size-16" style="width: 100%" @click="modal_propose">
                                <span class="margin-top-bot-auto">เสนอแผนงานขนส่ง</span>
                              </div>
                            </b-col>
                            <b-col lg="6">
                              <div class="d-flex justify-content-center big_btn_white font-size-16" style="width: 100%" @click="askCreateSpecificPlans()">
                                <span class="margin-top-bot-auto">สร้างแผนงานเฉพาะ</span>
                              </div>
                            </b-col>
                          </b-row>
                        </div>
                        <div v-else>
                          <b-row class="col-margin-bot-1">
                            <b-col>
                              <span class="plans_detail_title"> แผนงานขนส่งที่มีการเสนอ </span>
                            </b-col>
                          </b-row>
                          <b-row style="padding-right: 1rem">
                            <div v-for="(option, index) in order_offer_data" :key="index" class="order-row-data-offer plans-col" style="margin-left: 1rem; padding: 1rem">
                              <b-row class="col-margin-bot-1">
                                <b-col>
                                  <div class="order-detail">
                                    <b-row class="col-margin-bot-1 font-size-16">
                                      <b-col lg="6">
                                        <img v-if="option.vehicle_type_group_id == '1'" src="@/assets/images/truck_img/4_wheels.png" style="width: 70px; height: 34px" />
                                        <img v-else-if="option.vehicle_type_group_id == '2'" src="@/assets/images/truck_img/6_wheels.png" style="width: 70px; height: 34px" />
                                        <img v-else-if="option.vehicle_type_group_id == '3'" src="@/assets/images/truck_img/10_wheels.png" style="width: 70px; height: 34px" />
                                        <img v-else-if="option.vehicle_type_group_id == '4'" src="@/assets/images/truck_img/trailer2.png" style="width: 70px; height: 34px" />
                                        <img v-else-if="option.vehicle_type_group_id == '5'" src="@/assets/images/truck_img/trailer.png" style="width: 70px; height: 34px" />
                                        <img v-else-if="option.vehicle_type_group_id == '6'" src="@/assets/images/truck_img/tow_truck.png" style="width: 70px; height: 34px" />
                                        <span style="padding-left: 5px; color: #343c48; font-size: 24px">&nbsp;&nbsp;{{ option.truck_type_name }}</span>
                                      </b-col>
                                      <b-col lg="6">
                                        จำนวนเงิน : <span style="padding-left: 5px; color: #343c48; font-size: 24px"> {{ option.expected_price }} </span>
                                      </b-col>
                                    </b-row>
                                    <b-row class="col-margin-bot-1">
                                      <b-col lg="12">
                                        ประเภทสินค้า : <span style="color: #0098d1"> {{ option.good_type_name }} ({{ option.quantity }} ตัน) </span>
                                      </b-col>
                                    </b-row>
                                    <b-row class="col-margin-bot-1">
                                      <b-col lg="12">
                                        จุดรับ - ส่งสินค้า :
                                        <span style="color: #0098d1"> {{ option.arrival_address }} - {{ option.departure_address }} ({{ option.total_distance }} กิโลเมตร) </span>
                                      </b-col>
                                    </b-row>
                                    <b-row class="custom-margin">
                                      <b-col lg="3">
                                        <button class="btn btn-sm" style="color: #343c48; background: #ffffff; border: 1px solid #b0bac4" @click="open_modal_detail(option)">ดูแบบละเอียด</button>
                                      </b-col>
                                    </b-row>
                                  </div>
                                </b-col>
                              </b-row>
                              <b-row class="col-margin-bot-1 font-size-14">
                                <b-col lg="6">
                                  <b-button variant="primary" style="width: 100%" @click="routersClick2(option)">ไปหน้า "แผนงานขนส่ง"</b-button>
                                </b-col>
                                <b-col lg="6">
                                  <b-button variant="danger" style="width: 100%" @click="askCanclePropose(option)">ยกเลิกการเสนองาน</b-button>
                                </b-col>
                              </b-row>
                              <b-row class="col-margin-bot-1 font-size-14">
                                <b-col>
                                  <span class="d-sm-inline-block">Tip : คุณสามารถตรวจสอบสถานะแผนงานขนส่งของคุณได้จากเมนู "<img src="@/assets/images/right_bar_menu/box.svg" height="18px" width="18px" style="margin-left: 0.5rem; margin-right: 0.5rem" />แผนงานขนส่ง"</span>
                                </b-col>
                              </b-row>
                            </div>
                          </b-row>
                        </div>
                      </simplebar>
                    </div>
                  </div>
                </b-col>
                <!-- route map -->
                <b-col>
                  <div class="card-body" style="max-height: calc(100vh - 141px); min-height: calc(100vh - 141px); padding 1.25rem; margin-right: 0.6rem;">
                    <div style="padding: 1% 1% 0 1%">
                      <RighrMap :obj="plan_data_selcet.rawData" />
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </transition>

        <div style="padding: 2rem 2rem 0rem 2rem">
          <b-row>
            <b-col lg="2" class="col-margin-bot-1">
              <!-- <div class="form-group">
                <b-form-input v-model="selectedDeparture" class="form-control grey_hover" type="text" placeholder="จังหวัด(ต้นทาง)">
                  <i class="ri-search-line" />
                </b-form-input>
              </div> -->
              <div class="form-group" @click="click_select_depart()">
                <b-form-input v-model="selectedDeparture" class="form-control grey_hover" type="text" placeholder="จังหวัด(ต้นทาง)" disabled />
              </div>
              <b-modal id="modal_prov_depart" centered size="xl" :title="lang.title" title-class="font-size-24" hide-footer>
                <ProvinceComp :obj="obj_sel_depart" @select_prov="select_prov_depart" />
              </b-modal>
            </b-col>
            <b-col lg="2" class="col-margin-bot-1">
              <!-- <div class="form-group">
                <b-form-input v-model="selectedArrival" class="form-control" type="text" placeholder="จังหวัด(ปลายทาง)" />
              </div> -->
              <div class="form-group" @click="click_select_arrive()">
                <b-form-input v-model="selectedArrival" class="form-control" type="text" placeholder="จังหวัด(ปลายทาง)" disabled />
              </div>
              <b-modal id="modal_prov_arrive" centered size="xl" :title="lang.title" title-class="font-size-24" hide-footer>
                <ProvinceComp :obj="obj_sel_arrive" @select_prov="select_prov_arrive" />
              </b-modal>
            </b-col>
            <b-col lg="2" class="col-margin-bot-1">
              <!-- <div class="form-group">
                <b-form-input v-model="selectedTruckType" class="form-control" type="text" placeholder="เลือกประเภทรถ" />
              </div> -->
              <div class="form-group" @click="activeSelectTruckType()">
                <b-form-input v-model="selectedTruckType" class="form-control" type="text" placeholder="เลือกประเภทรถ" disabled />
              </div>
              <b-modal id="modal_select_truckType" centered size="lg" :title="lang.plans_detail_display.truck_type" title-class="font-size-24" hide-footer>
                <TruckTypeComp :obj="[dataTruckType, dataTruckTypeGroup, 'carrier']" @sendTruckTypeData="getTruckType_select" />
              </b-modal>
            </b-col>
            <b-col lg="2">
              <date-picker v-model="date_search" input-class="form-control" format="DD-MM-YYYY" type="date" range placeholder="วันเริ่มเดินทาง" />
            </b-col>
            <b-col lg="2" class="col-margin-bot-1">
              <div class="d-flex justify-content-center big_btn_blue" @click="search_carier()">
                <span class="margin-top-bot-auto"><i class="ri-search-line" /> ค้นหา</span>
              </div>
              <!-- <div class="d-flex justify-content-center big_btn_blue">
                <span class="margin-top-bot-auto"><i class="ri-search-line" /> ค้นหา</span>
              </div> -->
            </b-col>
            <b-col />
          </b-row>
          <b-row v-if="plans_data_status">
            <simplebar class="card-body plans-main-display" style="padding: 0px">
              <transition-group name="bounce" class="custom-transition" enter-active-class="bounceInLeft" leave-active-class="bounceOutRight" tag="div">
                <b-col v-for="(option, index) in plans_row_data" :key="index+1" lg="12" class="plans-col">
                  <div class="plans-row-data">
                    <div style="cursor: pointer;" @click="activePlansDisplay(option)">
                      <b-row class="custom-margin">
                        <b-col>
                          <span class="plans-row-data-title">
                            <img v-if="option.truck_type_group == '1'" src="@/assets/images/truck_img/4_wheels.png" width="60px" style="margin-right: 20px" />
                            <img v-else-if="option.truck_type_group == '2'" src="@/assets/images/truck_img/6_wheels.png" width="60px" style="margin-right: 20px" />
                            <img v-else-if="option.truck_type_group == '3'" src="@/assets/images/truck_img/10_wheels.png" width="60px" style="margin-right: 20px" />
                            <img v-else-if="option.truck_type_group == '4'" src="@/assets/images/truck_img/trailer2.png" width="60px" style="margin-right: 20px" />
                            <img v-else-if="option.truck_type_group == '5'" src="@/assets/images/truck_img/trailer.png" width="60px" style="margin-right: 20px" />
                            <img v-else-if="option.truck_type_group == '6'" src="@/assets/images/truck_img/tow_truck.png" width="60px" style="margin-right: 20px" />
                            {{ option.truck_type_name }}
                          </span>
                        </b-col>
                        <b-col>
                          <span class="plans-row-data-content"> {{ lang.number_of_car }} : </span>
                          <span class="plans-row-data-content font-color-blue">
                            {{ option.rawData.number_of_cars }}
                          </span>
                        </b-col>
                        <b-col>
                          <span class="plans-row-data-content"> {{ lang.payload }} : </span>
                          <span class="plans-row-data-content font-color-blue"> {{ option.rawData.payload }} {{ lang.tons }} </span>
                        </b-col>
                        <b-col>
                          <span class="plans-row-data-content"> {{ lang.create_date }} : </span>
                          <span class="plans-row-data-content font-color-blue">
                            {{ option.rawData.created | moment('dd DD/MM/YYYY HH:mm') }}
                          </span>
                        </b-col>
                      </b-row>
                      <b-row class="custom-margin">
                        <b-col>
                          <span class="plans-row-data-content">
                            {{ lang.pick_up_1 }}
                          </span>
                        </b-col>
                        <b-col>
                          <span class="plans-row-data-content">
                            <i class="ri-arrow-right-fill" />
                          </span>
                        </b-col>
                        <b-col>
                          <span class="plans-row-data-content">
                            {{ lang.drop_1 }}
                          </span>
                        </b-col>
                        <b-col />
                      </b-row>
                      <b-row class="custom-margin">
                        <b-col>
                          <span class="plans-row-data-content font-color-blue">
                            {{ option.rawData.depart_address1 }}
                            <br />
                            {{ option.rawData.depart_date | moment('dd DD/MM/YYYY HH:mm') }}
                          </span>
                        </b-col>
                        <b-col />
                        <b-col>
                          <span class="plans-row-data-content font-color-blue">
                            {{ option.rawData.arrive_address1 }}
                            <br />
                            {{ option.rawData.arrive_date | moment('dd DD/MM/YYYY HH:mm') }}
                          </span>
                        </b-col>
                        <b-col />
                      </b-row>
                    </div>
                    <hr />
                    <b-row>
                      <b-col class="d-flex align-items-center">
                        <!-- <img src="@/assets/images/plans_display/star_empty.png" width="24px" height="24px" style="margin-right: 0.6rem" /> -->
                        <i :class="option.star_flag == '1' ? 'ri-star-fill': 'ri-star-line'" :style="option.star_flag == '1' ? 'color:#FAAB00;cursor:pointer;font-size:25px;margin-right: 0.6rem;' : 'cursor:pointer;font-size:25px;margin-right: 0.6rem;'" @click="updateStar(option, index)" />
                        <img v-if="option.pic_profile" :src="option.pic_profile" width="40px" height="40px" style="margin-right: 0.6rem" class="rounded-circle avatar-xs" />
                        <img v-else src="@/assets/images/plans_display/Group 3361@2x.png" width="40px" height="40px" style="margin-right: 0.6rem" class="rounded-circle avatar-xs" />
                        {{ option.rawData.mover_name_carrier }}
                        <img src="@/assets/images/plans_display/star@2x.png" width="16px" height="16px" style="margin-left: 1rem" />
                        <span class="font-size-14" style="margin-right: 0.5rem"> ( {{ option.star_score }} ) </span>
                        <img src="@/assets/images/plans_display/lover.png" width="16px" height="16px" />
                        <span class="font-size-14"> ( {{ option.lover_score }} ) </span>
                      </b-col>
                      <b-col class="d-flex align-items-center">
                        <span v-if="option.count_offer > 0" class="status-plan"> เสนองานแล้ว </span>
                        <span v-else> - </span>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </transition-group>
            </simplebar>
          </b-row>
          <b-row v-else>
            <b-col>
              <br />
              <br />
              <br />
              <br />
              <br />
              <b-col lg="12" class="d-flex justify-content-center">
                <img src="@/assets/images/search.png" height="188px" />
              </b-col>
              <br />
              <b-col lg="12" class="d-flex justify-content-center">
                <span style="font-size: 24px; font-weight: 600;">
                  ไม่พบข้อมูลรถว่าง
                </span>
              </b-col>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>

    <b-modal id="modal_propose_plan" centered size="lg" title="เลือกแผนงานที่ต้องการเสนอ" title-class="font-18">
      <simplebar style="max-height: 67vh; min-height: 67vh; padding-top: 0px">
        <div v-for="(option, index) in plans_data_order" :key="index" class="order-row-data plans-col" style="width: 100%">
          <b-row>
            <b-col lg="1" class="d-flex flex-row-reverse align-items-center">
              <b-form-radio v-model="selectedIndexOrder" :value="index" name="radio-propose" />
            </b-col>
            <b-col lg="11">
              <div class="order-detail" @click="selectPlanSendOffer(index)">
                <b-row class="col-margin-bot-1">
                  <b-col lg="6">
                    <img v-if="option.vehicle_type_group_id == '1'" src="@/assets/images/truck_img/4_wheels.png" style="width: 70px; height: 34px" />
                    <img v-else-if="option.vehicle_type_group_id == '2'" src="@/assets/images/truck_img/6_wheels.png" style="width: 70px; height: 34px" />
                    <img v-else-if="option.vehicle_type_group_id == '3'" src="@/assets/images/truck_img/10_wheels.png" style="width: 70px; height: 34px" />
                    <img v-else-if="option.vehicle_type_group_id == '4'" src="@/assets/images/truck_img/trailer2.png" style="width: 70px; height: 34px" />
                    <img v-else-if="option.vehicle_type_group_id == '5'" src="@/assets/images/truck_img/trailer.png" style="width: 70px; height: 34px" />
                    <img v-else-if="option.vehicle_type_group_id == '6'" src="@/assets/images/truck_img/tow_truck.png" style="width: 70px; height: 34px" />
                    <span style="padding-left: 5px; color: #343c48; font-size: 24px">&nbsp;&nbsp;{{ option.truck_type_name }}</span>
                  </b-col>
                  <b-col lg="6">
                    จำนวนเงิน : <span style="padding-left: 5px; color: #343c48; font-size: 24px"> {{ option.expected_price }} </span>
                  </b-col>
                </b-row>
                <b-row class="col-margin-bot-1">
                  <b-col lg="12">
                    ประเภทสินค้า : <span style="color: #0098d1"> {{ option.good_type_name }} ({{ option.quantity }} ตัน) </span>
                  </b-col>
                </b-row>
                <b-row class="col-margin-bot-1">
                  <b-col lg="12">
                    จุดรับ - ส่งสินค้า :
                    <span style="color: #0098d1"> {{ findTwoPoint(option.arrival_address, option.departure_address) }} ({{ option.total_distance }} กิโลเมตร) </span>
                  </b-col>
                </b-row>
                <b-row class="custom-margin">
                  {{ option.date_appointment }}
                  <b-col lg="3">
                    <button class="btn btn-sm" style="color: #343c48; background: #ffffff; border: 1px solid #b0bac4" @click="open_modal_detail(option)">ดูแบบละเอียด</button>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </div>
      </simplebar>
      <template #modal-footer class="d-flex align-items-center">
        <b-button size="md" variant="success" @click="sent_propose()"> ยืนยัน </b-button>
        <b-button size="md" variant="outline-secondary" @click="cancel_propose()"> ยกเลิก </b-button>
      </template>
    </b-modal>
    <PlanDetail ref="plandetail" :obj="{}" />
  </Layout>
</template>
<style scoped>
@import '../../../../node_modules/ol-layerswitcher/dist/ol-layerswitcher.css';
body {
  overflow: hidden;
}
hr {
  border: 0.5px solid #e4e4e4;
}
.col-main-menu-tran {
  padding: 0px;
}
.h-100vh {
  height: 100% !important;
}
.plans-row-data {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #b0bac4;
  border-radius: 5px;
  opacity: 1;
  max-height: 280px;
  min-height: 250px;
  padding: 1rem;
}
.plans-row-data:hover {
  transition: 0.3s;
  border: 1px solid #03aeef;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}
.plans-col {
  /* transition: all 1s; */
  display: inline-block;
  margin: 0 0 0.5rem 0;
}
.plans-main-display {
  max-height: calc(100vh - 260px);
  min-height: calc(100vh - 260px);
}
.plans-row-data-title {
  font-size: 24px;
  font-weight: 500;
}
.plans-row-data-content {
  font-size: 16px;
  font-weight: 500;
}
.custom-margin {
  margin-bottom: 0.5rem;
}
.summary-of-plan.active {
  position: absolute;
  width: 100%;
  z-index: 13;
}
.plans_detail_title {
  font-size: 32px;
  font-weight: 500;
}
.avatar-title {
  /* background-color: #fff !important; */
  background-color: hsla(197, 100%, 89%, 0) !important;
}
.col-margin-bot-1 {
  margin-bottom: 0.6rem;
}
.service-provider-display {
  padding: 20px;
}
.status-plan {
  background-color: #0098d1;
  color: #ffffff;
  padding: 0.1rem 0.6rem 0.1rem 0.6rem;
  border-radius: 1rem;
}

.order-row-data {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ffffff;
  border-radius: 5px;
  opacity: 1;
}
.order-row-data:hover {
  transition: 0.3s;
  border: 1px solid #03aeef;
  cursor: pointer;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}
.order-row-data-offer {
  background: #ffffff 0% 0% no-repeat padding-box;
  /* border: 1px solid #ffffff; */
  border: 1px solid #b0bac4;
  border-radius: 5px;
  opacity: 1;
}
.order-detail {
  background: #f2f9fc 0% 0% no-repeat padding-box;
  padding: 0.5rem 1rem;
  border-radius: 5px;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #ffffff;
}
</style>
